body {
  margin: 0;
  padding: 0;

  background: url('../public/images/background.jpg') no-repeat center center fixed;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: cover;
  /* height: 100vh; */
  /* display: flex; */

  align-items: center;
  justify-content: center;

  font-family: Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 1);
}
